.temp {
  padding: 3rem;
  color: white;
  font-weight: bold;
  font-size: 2rem;
}

//cancun airport brand blue color
$brand-blue: #27c1de;
$dark-blue: #3498db;

//cancun logo colors
//Cancun logo colors used in cun logo
$cancun-red: #ec2031;
$cancun-green: #99ca3c;
$cancun-orange: #f9a51d;

$cancun-hot-pink: #ea088c;
$cancun-purple: #b3208d;
$cancun-blue: #00aabd;

//services colors
$purple: #711b58; //airlines
$yellow-pea: #d8df20; //arrivals
$red: #cf2228; //bus
$turquoise: #19bdbe; //connecting
$hot-pink: #d91b5b; //Transportation
$green: #0b9444; //Tourist card
$dark-blue: #1e334e; //Immigration
$lime-green-light: #91c53f; //money
$red-warn: #ef1c24;
$visitax: #b93147;

$ultramarine: #21409a;
$sea-blue: #2a97c3;
$lime-green: #0b9444;
$cherry: #982264;
$yellow: #ffde15;
$orange: #f1582a;

.u-bg-visitax {
  background-color: $visitax;
}
.u-text-visitax {
  color: $visitax;
}
.u-border-visitax {
  border-color: $visitax;
}

.u-bg-lime-green-light {
  background-color: $lime-green-light;
}
.u-text-lime-green-light {
  color: $lime-green-light;
}
.u-border-lime-green-light {
  border-color: $lime-green-light;
}

.u-bg-dark-blue {
  background-color: $dark-blue;
}
.u-text-dark-blue {
  color: $dark-blue;
}
.u-border-dark-blue {
  border-color: $dark-blue;
}

.u-bg-green {
  background-color: $green;
}
.u-text-green {
  color: $green;
}
.u-border-green {
  border-color: $green;
}

.u-bg-hot-pink {
  background-color: $hot-pink;
}
.u-text-hot-pink {
  color: $hot-pink;
}
.u-border-hot-pink {
  border-color: $hot-pink;
}

.u-bg-purple {
  background-color: $purple;
}
.u-text-purple {
  color: $purple;
}
.u-border-purple {
  border-color: $purple;
}

.u-bg-yellow-pea {
  background-color: $yellow-pea;
}
.u-text-yellow-pea {
  color: $yellow-pea;
}
.u-border-yellow-pea {
  border-color: $yellow-pea;
}

.u-bg-turquoise {
  background-color: $turquoise;
}
.u-text-turquoise {
  color: $turquoise;
}
.u-border-turquoise {
  border-color: $turquoise;
}

.u-bg-red {
  background-color: $red;
}
.u-text-red {
  color: $red;
}
.u-border-red {
  border-color: $red;
}

.u-bg-red-warn {
  background-color: $red-warn;
}
.u-text-red-warn {
  color: $red-warn;
}
.u-border-red-warn {
  border-color: $red-warn;
}

.p-header {
  //background-color: $brand-blue;
  //color: white;
  &__top {
    height: 3rem;
  }

  &__branding {
    background-color: $brand-blue;
    color: white;
    border-bottom: 1px solid tint-color($brand-blue, 60%);

    .container {
      //height: 80px;

      @include media-breakpoint-up(md) {
        //height: 120px;
      }
    }
  }

  &__branding-message {
    font-weight: bold;
    font-size: 0.65rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 0.85rem;
    }
  }

  &__logo {
    margin-right: 1rem;
    img {
      width: 103.5px;
      height: 47.5px;
      @include media-breakpoint-up(md) {
        width: 207px;
        height: 95px;
      }
    }
  }

  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
    line-height: 1.2rem;
    color: white;
    margin-bottom: 0;
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      margin-top: 0.2rem;
      line-height: 2rem;
    }

    span {
      font-size: 1.06rem;
      text-transform: capitalize;
      letter-spacing: 0;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }
  }

  &__title-spanish {
    font-family: "Open Sans", sans-serif;
    font-size: 2.4rem;
    letter-spacing: 0.25rem;
    line-height: 1.5rem;
    color: white;
    margin-top: -0.7rem;
    //margin-top: .5rem;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      margin-top: -2rem;
      line-height: 2.5rem;
    }

    span {
      font-size: 0.79rem;
      text-transform: capitalize;
      letter-spacing: 0;

      @include media-breakpoint-up(md) {
        font-size: 1.15rem;
      }
    }
  }

  &__language {
    a {
      text-decoration: none;
      color: black;
      &:hover {
        color: $primary;
      }
    }
  }
}

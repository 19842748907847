//Organizing using itcss
//Reference: https://css-tricks.com/combining-the-powers-of-sem-and-bio-for-improving-css/

//Settings
/*
Settings are generally a collection of variables that do not generate CSS, but are applied to classes.
*/
@import "settings/base";
@import "settings/color";
@import "settings/typography";
@import "settings/animation";

//Tools
/*
Tools also will not produce any CSS yet and are typically preprocessor functions that help write or extend properties on classes:
*/
@import "tools/functions";
@import "tools/placeholders";
@import "tools/mixins";
@import "tools/media-queries";

/*---------------------------------------------------------------------------------------------------------*/

//Vendors
/*
Vendors are third-party styles that are being used on a project. Think of things like reset.css, normalize.css, or even Foundation and Bootstrap.
*/

//This is where you override theme colors
$primary: $brand-blue;
$secondary: $cancun-orange;
$success: $lime-green;
$danger: $cancun-red;
$warning: $yellow;
$info: $lime-green-light;

//Bootstrap 5
// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Include custom variable default overrides here
@import "custom";

$dropdown-bg: $primary;
$dropdown-link-color: $light;
$enable-rounded: false;
$body-color: $gray-700;

//$navbar-dark-color:                 rgba($white, .55) !default;
//Custom navbar
$navbar-dark-color: $white;
$navbar-dark-active-color: $warning;
$navbar-dark-hover-color: $warning;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: $white;
.navbar-dark .navbar-toggler {
  background-color: #0781d3;
}
.navbar .active {
  font-weight: bold;
}

//$breadcrumb-divider: quote(">");

$font-weight-light: $gray-500;
$font-weight-normal: $gray-700;
$font-weight-bold: $gray-600;

// Optional
// Layout & components
@import "~bootstrap/scss/root"; //
@import "~bootstrap/scss/reboot"; //
@import "~bootstrap/scss/type"; //
@import "~bootstrap/scss/images"; //
@import "~bootstrap/scss/containers"; //
@import "~bootstrap/scss/grid"; //
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown"; //
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav"; //
@import "~bootstrap/scss/navbar"; //
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

/*---------------------------------------------------------------------------------------------------------*/

//Objects
/*
Objects (namespace: o-) are used for design patterns, such as layouts, where items are being arranged rather than decorated.
Object classes are used across all pages, so if you make any changes to the object classes,
you should be very careful because any changes are going to affect each and every page throughout the website.

The most common object classes I use are:

.o-page: the most outer container which usually contains max-width: 100vw and overflow: hidden.
.o-main: the outer container for the main area.
.o-container: the outer container for components and it usually provides a fixed width.
.o-content: in case if any extra configuration is needed for the actual content area.
.o-grid: if a grid layout with different number of columns are required.
*/

/*---------------------------------------------------------------------------------------------------------*/

//Elements
/*
Elements (namespace: e-) are the HTML native elements which we would not style based on the class names.
For example, we should provide default styles to <a> element rather than .link class.

It is because, especially in a CMS like WordPress, you wouldn't want to add a class every single time you want to use a link in content.
 Hence, we provide a default style to the <a> element so without any class, the link will still have good-looking styles.
*/

body {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  color: $gray-600;
}

h1 {
}

/*---------------------------------------------------------------------------------------------------------*/

//Components
/*
A component (namespace: c-) is a small feature that makes up a part of the website.
Think buttons, accordions, sliders, modal dialogs, etc.
Each component is fully functional by itself and does not rely on any other components.
This fact should be considered when you name the component.
*/
@import "components/hero";
@import "components/services-chooser";
@import "components/main-heading";

.c-language {
  display: flex;
  height: 2.5rem;
  align-items: center;

  img {
    margin-right: 0.25rem;
  }
  p {
    margin-bottom: 0;
  }

  a {
    font-size: 0.85rem;
    color: white;
  }
}

.c-warning-box {
  margin-top: 1rem;
  border: 3px solid $danger;
  background-color: white;

  li {
    margin-bottom: 0.5rem;
  }

  &--overlay {
    @include media-breakpoint-up(md) {
      margin-top: -3rem;
      position: relative;
      z-index: 100;
    }
  }
}

.c-tabs {
  margin-top: 3rem;

  .nav-link {
    color: #495057;
  }

  @include media-breakpoint-up(md) {
    background-color: $primary;
    padding: 1rem;
    position: relative;
    margin-top: -8rem;
  }
}

//From this tutorial: https://blog.duda.co/responsive-google-maps-for-your-website
.c-map-responsive {
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.c-card-fixed-height {
  height: 22rem;
}

/*---------------------------------------------------------------------------------------------------------*/

//Patterns
/*
A lot of developers/engineers use the terms component and pattern synonymously and that’s totally fine
if you are more comfortable with that. It is just my preference to separate those two terms.

For a general rule of thumb, I think of a pattern (namespace: p-) as a combination of components but in a way that is not scaleable.

For example, I would consider the accordion as a component. It is scaleable and reusable on its own,
meaning that it can be used in other parts of the website without making any changes even
if the accordion would contain other components such as buttons.

On the other hand, the header, for example, would be a pattern because it is not scaleable
(the header cannot be used in the content or sidebar area) and also contains other components
such as buttons, accordions, menus, logos, search form etc.
*/

@import "patterns/header";
@import "patterns/footer";

.p-section {
  padding-top: 3rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
/*---------------------------------------------------------------------------------------------------------*/

//Scope
/*
Be warned; I only use the scope if it’s absolutely necessary. The purpose of the scope (namespace: s-) is to give us the highest specificity so we can overwrite any styles for a specific purpose.

Remember, If you find yourself using the scope class many times, you might be writing styles that are too specific and you should consider refactor your CSS structure.
*/

/*---------------------------------------------------------------------------------------------------------*/

//Utility
/*
Sometimes you may want to make changes only for a certain style in a specific place. In that case, utility (namespace: u-) classes can help us update it without changing the whole CSS structure.
*/

.u-btn {
  display: inline-block;
  text-decoration: none;
  padding: 1rem;
}

.u-text-purple {
  color: $cancun-purple;
}

.u-btn-purple {
  color: white;
  background-color: $cancun-purple;
  border-color: $cancun-purple;

  &:hover {
    background-color: tint-color($cancun-purple, 20%);
  }
}

.u-text-ultramarine {
  color: $ultramarine;
}

.u-btn-ultramarine {
  color: white;
  background-color: $ultramarine;
  border-color: $ultramarine;
  &:hover {
    background-color: tint-color($ultramarine, 20%);
  }
}

.u-text-hot-pink {
  color: $hot-pink;
}

.u-btn-hot-pink {
  color: white;
  background-color: $hot-pink;
  border-color: $hot-pink;
  &:hover {
    background-color: tint-color($hot-pink, 20%);
  }
}

.u-text-orange {
  color: $orange;
}

.u-btn-orange {
  color: white;
  background-color: $orange;
  border-color: $orange;
  &:hover {
    background-color: tint-color($orange, 20%);
  }
}

.breadcrumb--white .breadcrumb-item + .breadcrumb-item::before {
  color: white;
}

.c-map-legend {
  li {
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
  span {
    padding-right: 0.5rem;
  }
  img {
    width: 50px;
    height: 50px;
    background-color: #36a9e1;
    padding: 10px;
    border-radius: 25%;
  }
}

.visitax-button {
  background-color: #b93147;
  border: 2px solid white;
}

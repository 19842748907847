.c-services-chooser {
  &__btn {
    height: 3.5rem;
    color: white;
    border-radius: 0;
    border-bottom: 1px solid $primary;
    line-height: 3.5rem;
    text-decoration: none;
    padding-left: 1rem;
    padding-right: 1rem;

    &--normal {
      background-color: #3498db;

      &:hover {
        background-color: #0781d3;
      }
    }

    &--active {
      background-color: $primary;
      &:hover {
        color: white;
      }
    }

    &--action {
      &:hover {
        background-color: $secondary;
        color: white;
      }
    }
  }
}


$main-heading-height: 3rem;
$main-heading-height-sm: 4rem;
$main-heading-height-md: 6rem;

.c-main-heading{

  &__icon{

    height: $main-heading-height;

    @include media-breakpoint-up(sm) {
      height: $main-heading-height-sm;
    }

    @include media-breakpoint-up(md) {
      height: $main-heading-height-md;
    }
  }

  &__title-wrap{
    border-width: 2px;
    border-style: solid;


    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    align-items: center;

    height: $main-heading-height;

    @include media-breakpoint-up(sm) {
      height: $main-heading-height-sm;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include media-breakpoint-up(md) {
      height: $main-heading-height-md;
      border-width: 5px;
    }
  }

  &__title{
    //color: #19BDBE;
    font-size: 1.25rem;

    @include media-breakpoint-up(sm) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
    }
  }
}
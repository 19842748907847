.p-footer{
  //color: white;
}

.p-footer__content {
  padding-top: 3rem;
}

.p-footer__bottom{

}

.p-footer__logos{
  //background-color: white;
  //padding: .5rem 2rem;
}

.p-footer a{
  color: white;
  text-decoration: none;
}

.p-footer ul{
  list-style-type: none;
  padding: 0;
  line-height: 2rem;

  li{
    font-weight: bold;
  }
}

.p-footer__heading {
  color: white;
  border-bottom: 2px solid white;
  padding-bottom: .5rem;
  margin-bottom: 1.5rem;
}
// Based on this tutorial
//https://www.developerdrive.com/full-page-hero-image-html-css/
.c-hero {
  //border: 10px solid white;
  box-shadow: 0 4px 3px -3px gray;
  position: relative;
  width: 100%;
  padding-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      //height: 500px;
    }

    @include media-breakpoint-up(lg) {
      //height: 600px;
    }
  }

  &__title {
    color: white;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
      text-shadow: -1px 1px 3px rgba(150, 150, 150, 0.9);
    }
  }

  &__content {
    position: relative;
  }

  &--home {
    @include media-breakpoint-up(md) {
      height: 600px;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-airport-portada-935.jpg");
      background-position: left;
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-airport-portada-1980.jpg");
      background-position: left;
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-airport-portada-2200.jpg");
      background-position: left;
    }
  }

  &--service {
    //height: 450px;
    height: 300px;

    @include media-breakpoint-up(sm) {
      height: 400px;
      padding-top: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 450px;
      padding-top: 0;
    }

    @include media-breakpoint-up(xl) {
      height: 500px;
      padding-top: 0;
    }
  }

  &--arrivals {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/arrivals-450.jpg");
    background-position: center;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/arrivals-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/arrivals-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/arrivals-2200.jpg");
    }
  }

  &--connecting {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/connecting-450.jpg");
    background-position: top center;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/connecting-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/connecting-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/connecting-2200.jpg");
    }
  }

  &--customs {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/customs-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/customs-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/customs-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/customs-2200.jpg");
    }
  }

  &--dutyfree {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/dutyfree-2200.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/dutyfree-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/dutyfree-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/dutyfree-2200.jpg");
    }
  }

  &--lockers {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/lockers-450.jpg");
    background-position: left;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lockers-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lockers-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lockers-2200.jpg");
    }
  }

  &--lost {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/lost-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lost-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lost-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/lost-2200.jpg");
    }
  }

  &--meeting {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/meeting-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/meeting-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/meeting-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/meeting-2200.jpg");
    }
  }

  &--parking {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/parking-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/parking-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/parking-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/parking-2200.jpg");
    }
  }

  &--private {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/private-planes-450.jpg");
    background-position: left;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/private-planes-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/private-planes-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/private-planes-2200.jpg");
    }
  }

  &--restaurant {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/restaurants-450.jpg");
    background-position: center;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/restaurants-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/restaurants-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/restaurants-2200.jpg");
    }
  }

  &--vip {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/vip-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/vip-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/vip-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/vip-2200.jpg");
    }
  }

  &--car-rental {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/rentals-450.jpg");
    background-position: left;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/rentals-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/rentals-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/rentals-2200.jpg");
    }
  }

  &--bus {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/bus-450.jpg");
    background-position: left;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/bus-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/bus-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/bus-2200.jpg");
    }
  }

  &--hotels {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/hotels-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/hotels-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/hotels-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/hotels-2200.jpg");
    }
  }

  &--tours {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/tours-450.jpg");
    background-position: left;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/tours-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/tours-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/tours-2200.jpg");
    }
  }

  &--cancun {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/cancun-hotel-zone-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-2200.jpg");
    }
  }

  &--company {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/cancun-hotel-zone-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/cancun-hotel-zone-2200.jpg");
    }
  }

  &--riviera-maya {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/riviera-maya-450.jpg");
    background-position: right;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/riviera-maya-935.jpg");
      background-position: center;
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/riviera-maya-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/riviera-maya-2200.jpg");
    }
  }

  &--airlines {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/airlines-450.jpg");
    background-position: center;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/airlines-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/airlines-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/airlines-2200.jpg");
    }
  }

  &--regulations {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../assets/img/hero/regulations-450.jpg");
    background-position: center;

    @include media-breakpoint-up(sm) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/regulations-935.jpg");
    }

    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/regulations-1980.jpg");
    }

    @include media-breakpoint-up(xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        url("../assets/img/hero/regulations-2200.jpg");
    }
  }
}
